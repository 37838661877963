import React from 'react';
import { Container } from '../../components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.json';

const name = "James Edwards-Jones";
export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>{name}</h2>
        <span>
          Built with React, Gatsby and Netlify-CMS
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img height="30" src={icon} alt={name} />
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
);

export default Footer;
